import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { blogData } from "../_mockdata/blogs";

import ArticlePreviewMini from "./articlepreviewmini";

interface Props {
  data: {
    allPrismicArticle: {
      edges: Array<any>;
    };
  };
  startIndex?: number | 0;
}

interface Article {
  image: string;
  date: Date;
  updatedDate?: Date;
  author: string;
  title: string;
  tags: Array<string>;
  link: string;
}

const ArticleFeed = ({ data, startIndex }: Props) => {
  const [page, setPage] = useState(1);

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePrevPage = () => {
    setPage(page - 1);
  };

  return (
    <div className="col-lg-8 mb-5">
      <div className="row">
        {/* Mapping Data Into Article Previews */}
        {data.allPrismicArticle.edges.slice(startIndex).map((item: any) => (
          <div
            className="col-lg-4 d-flex align-items-stretch"
            key={item.node.uid}
          >
            <ArticlePreviewMini data={item.node} />
          </div>
        ))}
      </div>

      {data.allPrismicArticle.edges.length === 0 && <p>Coming Soon</p>}
      {/*
      {articles.length > 9 && (
        <div className="d-flex justify-content-center">
          <Button onClick={() => handleNextPage()}>
            Next Page <FaArrowRight style={{ marginLeft: "7px" }} />
          </Button>
        </div>
      )} */}
    </div>
  );
};

export default ArticleFeed;

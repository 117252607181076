import { Link } from "gatsby";
import React from "react";
import { formatDate } from "../utils/formatDate";

import { blogData } from "../_mockdata/blogs";

interface Props {
  dataSource: string;
}

const RelatedContent = () => {
  return (
    <div className="related-content mb-5">
      <h3>Related Content</h3>
      <hr />
      {/* {blogData.map((blog) => (
        <Link to={blog.link} className="related-link">
          <h4>{blog.title}</h4>
          <p>{`${formatDate(blog.date)} | ${blog.author}`}</p>
        </Link>
      ))} */}
      <p>Coming Soon</p>
    </div>
  );
};

export default RelatedContent;
